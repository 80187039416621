import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import useScroll from '~/utils/use-scroll';
import Header from '~/components/header';
import Layout from '~/components/layout';
import Section from '~/components/section';

import banner from './assets/banner.jpg';
import ContactForm from './components/contact-form';

const features = [
  {
    icon: 'hands-heart',
    iconType: 'solid',
    title: 'Hand Made',
    description: 'Each pen is lovingly hand-crafted and I do my best to source my materials from other artists. Your appreciation also goes to all the other artists.',
  },
  {
    icon: 'fingerprint',
    iconType: 'solid',
    title: 'Unique',
    description: 'I only make one pen at a time, and I won\'t copy another artist or even myself. Each pen is as unique as its buyer.',
  },
  {
    icon: 'image',
    iconType: 'solid',
    title: 'Elegant Works of Art',
    description: 'Each piece I create should be able to be displayed as a work of art on its own. But be sure you don\'t leave it there too long.',
  },
  {
    icon: 'globe-americas',
    iconType: 'solid',
    title: 'Everyday Tools',
    description: 'Though they can be displayed, they are also meant to be used. They will stand up to day-to-day use, while still retaining their beauty.',
  },
  {
    icon: 'hourglass-half',
    iconType: 'solid',
    title: 'Live in the Moment',
    description: 'Appreciate the now. Get a gift for yourself or a friend that can be admired and appreciated now and for the rest of their lives.',
  },
  {
    icon: 'people-carry',
    iconType: 'solid',
    title: 'Support Local Businesses',
    description: 'Though we may not be local to you, supporting small businesses helps enrich all our communities. All my materials are from small businesses too.',
  },
];

export default function Landing({ section, endorsements, ...props }) {
  const [ scrollTo, registerScroll ] = useScroll(54);

  useEffect(() => {
    if (section) {
      scrollTo(section);
    }
  }, [ scrollTo, section ]);

  return (
    <Layout type="homepage" {...props}>
      <Section title="The Introduction" id="intro" className="wrapper style1">
        <div className="image featured">
          <img src={banner} alt="" />
        </div>
        <p className="style1">So in case you were wondering what this is all about...</p>
        <p className="style2">
          I create handcrafted pens.<br className="mobile-hide" />
          Bespoke &middot; Artisanal &middot; One of a Kind
        </p>
        <p className="style3">
          I create custom ordered pens, bottle openers, wine stoppers, along with a variety of other similar projects.
          I can work with woods, resins, and hybrids; I can sometimes find historic wood, such as from a movie set;
          I can incorporate your words, symbols, and even photos.
        </p>
        <ul className="actions">
          <li>
            <button type="button" className="button style3 large" onClick={() => scrollTo('details')}>Learn More</button>
          </li>
        </ul>
      </Section>
      <Section title="The Details" id="main" className="wrapper style2" ref={registerScroll('details')}>
        <div className="row gtr-150">
          <div className="col-12">
            <div id="content">
              <section className="features">
                <Header title="About My Work" className="style1">
                  Why should you choose me to craft your perfect pen?
                </Header>
                <div className="feature-list">
                  <div className="row">
                    {features.map(({ icon, iconType, title, description }) => (
                      <div key={title} className="col-6 col-12-medium">
                        <section>
                          <h3 className={`icon fa-${icon} ${iconType}`}>{title}</h3>
                          <p>{description}</p>
                        </section>
                      </div>
                    ))}
                  </div>
                </div>
                <ul className="actions special">
                  <li>
                    {/* <Link to="/order" className="button style1 large">Get Started</Link> */}
                    <button type="button" className="button style1 large" onClick={() => scrollTo('contact')}>Get Started</button>
                  </li>
                  <li>
                    <button type="button" className="button style2 large" onClick={() => scrollTo('endorsements')}>More Info</button>
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </Section>
      <Section title="The Endorsements" className="wrapper style3" ref={registerScroll('endorsements')}>
        <div className="row aln-center highlights">
          {endorsements.map(({ id, label, author, image, notes }) => (
            <div key={id} className="col-4 col-12-medium">
              <section className="highlight">
                <div className="image">
                  <img src={image[0].thumbnails.large.url} alt={image[0].alt || label} />
                </div>
                <h3>{label}</h3>
                {/* eslint-disable-next-line react/no-danger */}
                <div className="quote" dangerouslySetInnerHTML={{ __html: notes.html }} />
                <div className="author">{author}</div>
              </section>
            </div>
          ))}
        </div>
      </Section>
      <Section title="Get In Touch" id="footer" className="wrapper" ref={registerScroll('contact')}>
        <Header title="Got a project in mind?" className="style1">
          <p>
            Looking for the perfect pen? Or a similar project? Got a question?<br />
            Fill out the form below and I&apos;ll get back to you ASAP.
          </p>
        </Header>
        <div className="row">
          <div className="col-6 col-12-medium">
            <section>
              <ContactForm />
            </section>
          </div>
          <div className="col-6 col-12-medium">
            <section className="feature-list small">
              <div className="row">
                <div className="col-6 col-12-small">
                  <section>
                    <h3 className="icon solid fa-home">Mailing Address</h3>
                    <p>
                      Pens By Yetti<br />
                      1535 Van Ness Ave<br />
                      Reno NV 89503
                    </p>
                  </section>
                </div>
                <div className="col-6 col-12-small">
                  <section>
                    <h3 className="icon solid fa-comment">Social</h3>
                    <p>
                      <a href="https://twitter.com/PensByYetti" target="_blank" rel="noopener noreferrer">Twitter</a><br />
                      <a href="https://www.instagram.com/pensbyyetti/" target="_blank" rel="noopener noreferrer">Instagram</a><br />
                      <a href="https://www.facebook.com/PensByYetti/" target="_blank" rel="noopener noreferrer">Facebook</a>
                    </p>
                  </section>
                </div>
                <div className="col-6 col-12-small">
                  <section>
                    <h3 className="icon solid fa-envelope">Email</h3>
                    <p>
                      <a href="mailto:pens@pensbyyetti.com" target="_blank" rel="noopener noreferrer">pens@pensbyyetti.com</a>
                    </p>
                  </section>
                </div>
                <div className="col-6 col-12-small">
                  <section>
                    <h3 className="icon solid fa-phone">Phone</h3>
                    <p>
                      (775) 842-3793
                    </p>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Section>
    </Layout>
  );
}

Landing.propTypes = {
  section: PropTypes.string.isRequired,
  endorsements: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    image: PropTypes.arrayOf(PropTypes.shape({
      alt: PropTypes.string,
      url: PropTypes.string.isRequired,
      thumbnails: PropTypes.shape({
        large: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    })).isRequired,
    notes: PropTypes.shape({
      html: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
};
