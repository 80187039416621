import React, { useState } from 'react';

import Header from '~/components/header';

export default function ContactForm() {
  const [ name, setName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ message, setMessage ] = useState('');
  const [ state, setState ] = useState('ready');

  function handleReset(e) {
    e.preventDefault();

    setName('');
    setEmail('');
    setMessage('');
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setState('loading');

    const response = await fetch('https://script.google.com/macros/s/AKfycbwmXnCZxZ6xu6ZHiiwF1rpMyjvuj_OMXE29msXAgB50MGpkEJ_w/exec', {
      method: 'post',
      body: JSON.stringify({
        name,
        email,
        message,
      }),
    });

    const body = await response.json();

    if (body.result === 'success') {
      setState('success');
    }
    else {
      setState('error');
    }
  }

  if (state === 'success') {
    return (
      <Header title="Thanks!" className="style1">
        <p>
          Thanks for your input! I&nbsp;ll get back to you as soon as possible, usually within two business days.
        </p>
      </Header>
    );
  }

  if (state === 'ready' || state === 'loading' || state === 'error') {
    return (
      <form method="post" onSubmit={handleSubmit}>
        <div className="row gtr-50">
          <div className="col-6 col-12-small">
            <input type="text" placeholder="Name" required value={name} onChange={e => setName(e.target.value)} />
          </div>
          <div className="col-6 col-12-small">
            <input type="email" placeholder="Email" required value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <div className="col-12">
            <textarea rows="4" placeholder="Message" required value={message} onChange={e => setMessage(e.target.value)} />
          </div>
          {state === 'loading' && (
            <div className="col-12">
              Submitting form, please wait.
            </div>
          )}
          {state === 'error' && (
            <div className="col-12">
              There was an error processing the form. Please try again. If the error persists, please use an alternate form of contact to the right.
            </div>
          )}
          <div className="col-12">
            <ul className="actions">
              <li><input type="submit" className="style1" value="Send" /></li>
              <li><input type="reset" className="style2" value="Reset" onClick={handleReset} /></li>
            </ul>
          </div>
        </div>
      </form>
    );
  }
}
