import { createRef, useCallback } from 'react';
import useStableValue from '~/utils/use-stable-value';

export default function useScroll(offsetTop = 0) {
  const [ registry ] = useStableValue(() => new Map());

  const registerScroll = useCallback((key) => {
    let ref = registry.get();
    if (!ref) {
      ref = createRef();
      registry.set(key, ref);
    }

    return ref;
  }, [ registry ]);

  const scrollTo = useCallback((key) => {
    const el = registry.get(key);
    if (el && el.current) {
      window.scrollTo({
        left: window.scrollX,
        top: window.scrollY + el.current.getBoundingClientRect().top - offsetTop,
        behavior: 'smooth',
      });
    }
  }, [ registry, offsetTop ]);

  return [ scrollTo, registerScroll ];
}
