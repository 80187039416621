import { useCallback, useRef } from 'react';

export default function useStableValue(fn) {
  const ref = useRef();
  if (!ref.current) {
    ref.current = fn();
  }

  const setValue = useCallback((value) => {
    ref.current = value;
  }, []);

  return [ ref.current, setValue ];
}
