import PropTypes from 'prop-types';
import CustomPropTypes from 'custom-prop-types';
import React from 'react';
import { graphql } from 'gatsby';

import Landing from '~/scenes/landing';

export default function LandingPage({ location: { pathname, hash }, data: { endorsements } }) {
  endorsements = CustomPropTypes.transformAirtable(endorsements);

  return (
    <Landing path={pathname} section={hash.slice(1)} endorsements={endorsements} />
  );
}

LandingPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    endorsements: CustomPropTypes.airtable({
      label: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      image: CustomPropTypes.airtableAttachments({ thumbnails: [ 'large' ] }).isRequired,
      imageAlt: PropTypes.string.isRequired,
      notes: CustomPropTypes.airtableRemark().isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query getHome {
    endorsements: allAirtable(filter: { table: { eq: "endorsements" } }, limit: 3) {
      nodes {
        id
        data {
          label
          author
          image {
            url
            thumbnails {
              large {
                url
              }
            }
          }
          imageAlt
          notes {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
